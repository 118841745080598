import { nanoid } from "nanoid";
import { merge } from "smob";
import type { PartialDeep } from "type-fest";
import { createReference } from "../lib/firebase";
import { FirebaseStore } from "../lib/FirebaseStore";

const FIREBASE_KEY = "descendants";

// TYPES
// -----

export type ImageGenerationState = "empty" | "processing" | "processed" | "approved";

/** The information of one descendant (real or generated) in the family tree */
export interface DescendantData {
  id: string;

  /**
   * Urls for the images that are available for this person.
   * For a real person, this is the selfie, for a generated person, these are the generated images
   */
  images: string[];

  /**
   * The index of the image that is currently active for this person
   */
  chosenImageIndex: number;

  /**
   * The ids of the 2-3 descendants that are parents of this person
   */
  ancestorIds?: string[];

  /**
   * The ids of all users that are part of this person's family tree
   */
  userIds: string[];

  /**
   * Are the images of this Descendant being generated right now or ready for approval
   */
  imagesState: ImageGenerationState;

  /**
   * If we use 'alien/future' families we want to know which Descendant is a repeating group from the previous round
   */
  repeatedUsers?: boolean;
}

export interface DescendantsById {
  [id: string]: DescendantData;
}

// DESCENDANT
// ----------

interface DescendantOptions {
  eventPrefix: string;
  id: string;
}

export class Descendant extends FirebaseStore<DescendantData> {
  constructor({ eventPrefix, id }: DescendantOptions) {
    super(createReference(eventPrefix, FIREBASE_KEY, id));
  }
}

// ALL DESCENDANTS
// ---------------

interface AllDescendantsOptions {
  eventPrefix: string;
}

export class AllDescendantsStore extends FirebaseStore<DescendantsById> {
  constructor({ eventPrefix }: AllDescendantsOptions) {
    super(createReference(eventPrefix, FIREBASE_KEY));
  }

  async createDescendant(partialDescendant: Omit<PartialDeep<DescendantData>, "id">) {
    const id = nanoid();

    if (!partialDescendant.images?.length) {
      return "images-empty";
    }

    // Make sure the id is always the same as the ref.key
    // Merge default values into the partialDescendant if they are not present
    const descendant: DescendantData = merge({ id }, partialDescendant, {
      images: [],
      chosenImageIndex: 0,
      userIds: [],
      imagesState: "empty",
    });

    await this.update({ [id]: descendant });

    return null;
  }
}
