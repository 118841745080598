import invariant from "tiny-invariant";
import { createReference } from "../lib/firebase";
import { FirebaseStore } from "../lib/FirebaseStore";
import type { GroupedPresenceData } from "./PresenceStore";
import { PresenceStore } from "./PresenceStore";
import type { SequenceData } from "./SequenceStore";

const FIREBASE_KEY = "users";

// TYPES
// -----

export interface UserData {
  /** Used for sorting and consistently coupling them */
  creationDate: number | null;

  /** User name and unique identifier */
  name: string;

  /** The current interface that the user is seeing */
  currentInterface: string | null;

  /** The url of the user's selfie */
  selfieUrl: string | null;

  /** The urls of the images that are displayed in the interface */
  images: string[];

  /**
   * The ids of the partners that the user has in the current round
   * @note This includes the user itself
   */
  groupUserIds?: string[];

  /** The index of the user that is selected from the group to do something */
  groupSelectedIndex?: number | null;

  /**
   * The sounds that are scheduled to play on the users device.
   */
  sequence?: SequenceData | null;

  /** show a toaster text while this attribute exists */
  alertTextKey?: string | null;

  /** show a toaster while alertTextKey exists */
  alertButtonKey?: string | null;

  /** partner names in sentence-part form */
  partners?: string | null;

  /** mix of traits in sentence-part form */
  amalgamation?: string | null;
  /** traits to choose from: */
  somethingFun?: string | null;
  notInherited?: string | null;
  shouldListenTo?: string | null;

  innerCircle?: boolean | null; // for the game round

  /** optional child names either entered by the groups or random  */
  childName?: string | null;
  grandChildName?: string | null;
  greatGrandChildName?: string | null;
  greatGreatGrandChildName?: string | null;
  otherGreatGreatGrandChildName?: string | null;

  agreedToSelfieStorage?: boolean | null;

  familyTreeParentCount?: number | null;
}

export interface UsersByName {
  [name: string]: UserData;
}

export type CreateUserError =
  | "username-taken"
  | "username-invalid-length"
  | "username-invalid-characters";

// ALL USERS
// ---------

interface AllUsersOptions {
  eventPrefix: string;
}

export class AllUsersStore extends FirebaseStore<UsersByName> {
  private presenceStore: PresenceStore;

  constructor({ eventPrefix }: AllUsersOptions) {
    super(createReference(eventPrefix, FIREBASE_KEY));
    this.presenceStore = new PresenceStore({ eventPrefix });
  }

  /** Applies an update to all users in the database. */
  async updateAllUsers(partialUser: Partial<UserData>) {
    await this.transaction((users) => {
      for (const name in users) {
        users[name] = {
          ...users[name],
          ...partialUser,
        };
      }

      return users;
    });
  }

  /** Applies an update to all users in the database. */
  async updateOnlySelectedUsers(partialUser: Partial<UserData>, selection: string[]) {
    await this.transaction((users) => {
      for (const name in users) {
        if (selection.includes(name)) {
          users[name] = {
            ...users[name],
            ...partialUser,
          };
        }
      }

      return users;
    });
  }

  /** Applies an update to the group the user is part of in the database. */
  async updateUserGroup({
    name,
    ...partialUser
  }: Partial<Omit<UserData, "name">> & { name: string }) {
    const users = await this.get();

    return this.transaction(() => {
      invariant(users, "`users` should be defined");

      const user = users[name];
      invariant(user, `User with name '${name}' not found`);

      const userIds = user.groupUserIds ?? [];

      for (const id of userIds) {
        users[id] = {
          ...users[id],
          ...partialUser,
        };
      }

      return users;
    });
  }

  getNextUserGroupSelectedIndex({
    user,
    presence,
  }: {
    user: UserData;
    presence: GroupedPresenceData;
  }) {
    // Get the currently selected userId
    const groupUserIds = user.groupUserIds ?? [];
    const currentIndex = user.groupSelectedIndex ?? 0;
    const currentUserId = groupUserIds[currentIndex];

    // If no one is online, we can't select anyone else
    const onlineUsers = groupUserIds.filter((userId) =>
      presence.online.some((u) => u.userId === userId),
    );

    if (onlineUsers.length === 0) {
      return null;
    }

    // Pick the next online user
    const onlineIndex = onlineUsers.includes(currentUserId)
      ? onlineUsers.indexOf(currentUserId)
      : 0;
    const newOnlineIndex = (onlineIndex + 1) % onlineUsers.length;
    const newUserId = onlineUsers[newOnlineIndex];
    const newIndex = groupUserIds.indexOf(newUserId);

    invariant(newIndex !== -1, "New user index should be valid");

    return newIndex;
  }

  async incrementUserGroupSelectedIndex(name: string) {
    const [users, presence] = await Promise.all([this.get(), this.presenceStore.getPresence()]);
    const user = users?.[name];

    invariant(users, "`users` should be defined");
    invariant(user, `User with name '${name}' not found`);

    await this.updateUserGroup({
      name,
      groupSelectedIndex: this.getNextUserGroupSelectedIndex({ user, presence }),
    });
  }

  /**
   * Creates a new user in the database and returns an error if the username is invalid.
   * The username must be between 1 and 20 characters long and cannot contain any of the following characters: . $[ ]#
   */
  async createUser(username: string): Promise<CreateUserError | null> {
    const trimmedUsername = username.trim();

    if (trimmedUsername.length > 20 || trimmedUsername.length === 0) {
      return "username-invalid-length";
    }

    // https://firebase.google.com/docs/database/web/structure-data#how_data_is_structured_its_a_json_tree
    if (trimmedUsername.match(/[.$[\]#/]/)) {
      return "username-invalid-characters";
    }

    const usernameExists = await this.usernameExists(trimmedUsername);

    if (usernameExists) {
      return "username-taken";
    }

    const user: UserData = {
      name: trimmedUsername,
      creationDate: null,
      currentInterface: null,
      selfieUrl: null,
      images: [],
    };

    await this.update({ [trimmedUsername]: user });

    return null;
  }

  /**
   * Checks if a username exists.
   *
   * @note When it finds a username that matches, it will return the found username. Since the
   * search is case insensitive, the returned username might differ from the one provided. Please
   * use the returned username for any future operations.
   */
  async usernameExists(username: string) {
    const users = (await this.get()) ?? {};
    return (
      Object.keys(users).find((name) => name.toLowerCase() === username.trim().toLowerCase()) ??
      null
    );
  }

  async usersWithSelfie() {
    const users = (await this.get()) ?? {};
    const withSelfies: UserData[] = [];

    for (const name of Object.keys(users)) {
      if (users[name].selfieUrl) {
        withSelfies.push(users[name]);
      }
    }

    return withSelfies;
  }
}

// USER
// ----

interface UserOptions {
  eventPrefix: string;
  name: string;
}

export class UserStore extends FirebaseStore<UserData> {
  constructor({ eventPrefix, name }: UserOptions) {
    super(createReference(eventPrefix, FIREBASE_KEY, name));
  }
}
