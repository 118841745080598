import { useMediaQuery } from "@ancestors/components/useMediaQuery";
import { cn } from "@ancestors/components/utils";

interface ScreenWrapperProps {
  children: React.ReactNode;
}

export function ScreenWrapper({ children }: ScreenWrapperProps) {
  const isBigScreen = useMediaQuery("(min-width: 768px) or (min-height: 1024px)");

  return (
    <div
      className={cn(
        "grid h-dvh w-dvw select-none place-items-center overflow-clip bg-neutral-900 text-base",
        isBigScreen && "p-4",
      )}
    >
      <ScreenWrapperInner>{children}</ScreenWrapperInner>
    </div>
  );
}

export function ScreenWrapperInner({ children }: { children: React.ReactNode }) {
  // The `screen` class is here for the `useScreenSize` hook to determine its size

  return (
    <main className="screen h-full max-h-[1024px] w-full max-w-[768px] overflow-y-scroll bg-background shadow-lg">
      {children}
    </main>
  );
}
