import type { DatabaseReference, Unsubscribe } from "firebase/database";
import { get, onValue, runTransaction, set, update } from "firebase/database";
import type { PartialDeep } from "type-fest";
import type { Store } from "./Store";

export class FirebaseStore<T extends Record<string, any>> implements Store<T> {
  protected _ref: DatabaseReference;
  private _data: T | null = null;
  private _state: "idle" | "subscribed" = "idle";
  private _subscribers = new Set<(data: T | null) => void>();
  private _unsubscribeFromFirebase: Unsubscribe | null = null;

  constructor(ref: DatabaseReference) {
    this._ref = ref;
  }

  async get() {
    if (this._state === "subscribed") {
      return this._data;
    }

    const snapshot = await get(this._ref);
    return snapshot.exists() ? (snapshot.val() as T) : null;
  }

  async update(partialData: PartialDeep<T>) {
    return await update(this._ref, partialData);
  }

  async set(data: T | null) {
    return await set(this._ref, data);
  }

  async transaction(callback: (data: T | null) => T | null) {
    await runTransaction(this._ref, callback);
  }

  subscribe(callback: (data: T | null) => void) {
    this._subscribers.add(callback);

    this._unsubscribeFromFirebase ??= onValue(this._ref, (snapshot) => {
      this._data = snapshot.val() ?? null;
      this._state = "subscribed";
      this._subscribers.forEach((callback) => callback(this._data));
    });

    return () => {
      this._subscribers.delete(callback);

      if (this._subscribers.size === 0) {
        this.disconnect();
      }
    };
  }

  disconnect() {
    this._subscribers.clear();
    this._unsubscribeFromFirebase?.();
    this._unsubscribeFromFirebase = null;
    this._state = "idle";
  }
}
