import { Container } from "@ancestors/components/Container";
import { LogoAncestors } from "@ancestors/components/LogoAncestors";
import { cn } from "@ancestors/components/utils";

export function FullPageLoader() {
  return (
    <Container className="flex flex-col items-center justify-center gap-8">
      <LogoAncestors
        variant="gradient"
        className="max-w-[50%] animate-in fade-in fill-mode-backwards"
        style={{ animationDelay: "2s", animationDuration: "2s" }}
      />
      <div
        className="relative grid items-center justify-items-center animate-in fade-in fill-mode-backwards *:col-span-full *:row-span-full"
        style={{ animationDelay: "2.5s", animationDuration: "2s" }}
      >
        <Circle className="h-[50px] w-[50px]" style={{ animationDuration: "0.85s" }} />
        <Circle className="h-[75px] w-[75px]" style={{ animationDuration: "0.95s" }} />
        <Circle className="h-[100px] w-[100px]" style={{ animationDuration: "1.05s" }} />
      </div>
    </Container>
  );
}

function Circle({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      {...props}
      className={cn(
        "animate-spin rounded-full border-4 border-transparent border-t-primary opacity-50 ease-linear repeat-infinite",
        className,
      )}
    ></div>
  );
}
