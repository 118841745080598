import { createReference } from "../lib/firebase";
import { FirebaseStore } from "../lib/FirebaseStore";

const FIREBASE_KEY = "sequenceSettings";

// TYPES
// -----

export type SequenceSettings = {
  disabled?: boolean;
  syncDate?: number;
  syncWithServer?: boolean;
};

// SEQUENCE SETTINGS
// -----------------

interface SequenceSettingsStoreOptions {
  eventPrefix: string;
}

export class SequenceSettingsStore extends FirebaseStore<SequenceSettings> {
  constructor({ eventPrefix }: SequenceSettingsStoreOptions) {
    super(createReference(eventPrefix, FIREBASE_KEY));
  }
}
