import type { EventStore } from "@ancestors/models";
import { createContext, useContext } from "react";
import invariant from "tiny-invariant";

export interface EventStoreContextValue {
  eventStore: EventStore;
  setEventId: (eventId: string | null) => void;
}

export const eventStoreContext = createContext<EventStoreContextValue | null>(null);

export function useEventStore() {
  const context = useContext(eventStoreContext);
  invariant(context, "`useEventStore` must be used within a `EventStoreProvider`");
  return context;
}
