import { createReference } from "../lib/firebase";
import { FirebaseStore } from "../lib/FirebaseStore";

const FIREBASE_KEY = "sequence";

// TYPES
// -----

export interface SequenceStep {
  startTime: number;
  startSounds?: string[];
  stopSounds?: string[];
}

export interface SequenceData {
  name?: string;
  steps?: { [name: string]: SequenceStep };
}

// SEQUENCE
// --------

interface SequenceStoreOptions {
  eventPrefix: string;
}

export class SequenceStore extends FirebaseStore<SequenceData> {
  constructor({ eventPrefix }: SequenceStoreOptions) {
    super(createReference(eventPrefix, FIREBASE_KEY));
  }
}
