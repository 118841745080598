import { push, set } from "firebase/database";
import { createReference } from "../lib/firebase";
import { FirebaseStore } from "../lib/FirebaseStore";

const FIREBASE_KEY = "userAgent";

export class UserAgentStore extends FirebaseStore<string[]> {
  constructor({ eventPrefix }: { eventPrefix: string }) {
    super(createReference(eventPrefix, FIREBASE_KEY));
  }

  async push(userAgent: string) {
    await set(push(this._ref), userAgent);
  }
}
