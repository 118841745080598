import { forwardRef } from "react";
import { cn } from "../lib/utils";

type ContainerProps = React.HTMLAttributes<HTMLDivElement>;

/**
 * Adds default padding
 */
export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ className, ...props }: ContainerProps, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={cn("h-full overflow-scroll overscroll-contain p-4", className)}
      />
    );
  },
);

Container.displayName = "Container";
