import { Button } from "@ancestors/components/Button";
import { useCallback } from "react";
import { isDevelopment } from "~/lib/utils";

interface ErrorScreenProps {
  mode?: "detailed" | "minimal";
  error: unknown;
}

export function ErrorScreen({
  error,
  mode = isDevelopment() ? "detailed" : "minimal",
}: ErrorScreenProps) {
  const errorType = attemptGetFromUnknown(error, "type") ?? null;
  const message = attemptGetFromUnknown(error, "message") ?? "Unknown error";

  const handleReload = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4 p-4 text-center">
      <LucideCircleAlert />

      <p className="text-lg font-bold">
        Oh no!
        <br />
        Something went wrong 😢
      </p>

      <Button onClick={handleReload}>Refresh the page</Button>

      {mode === "detailed" ? (
        <pre className="whitespace-pre-wrap">
          {errorType && `[${errorType}]:`}
          {message.includes("\n") ? message : `\n${message}`}
        </pre>
      ) : (
        <p className="text-muted-foreground">
          If the error persists, please see us at the tech table.
        </p>
      )}
    </div>
  );
}

function attemptGetFromUnknown(unknown: unknown, key: string) {
  return typeof unknown === "object" && unknown !== null && key in unknown
    ? (unknown as any)[key]
    : undefined;
}

function LucideCircleAlert(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24" {...props}>
      <g fill="none" stroke="#FF7F7F" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
        <circle cx="12" cy="12" r="10"></circle>
        <path d="M12 8v4m0 4h.01"></path>
      </g>
    </svg>
  );
}
