import { merge } from "smob";
import type { PartialDeep } from "type-fest";
import { createReference } from "./firebase";
import { FirebaseStore } from "./FirebaseStore";
import type { Store } from "./Store";

interface MergedStoreOptions {
  path: string[];
  eventPrefix: string;
}

/**
 * A store that merges data in the global and event stores.
 * The data in the event store will override the data in the global store.
 */
export class MergedStore<T extends Record<string, any>> implements Store<T> {
  private _globalStore: Store<T>;
  private _globalData: T | null = null;
  private _unsubscribeGlobalStore: (() => void) | null = null;

  private _eventStore: Store<T>;
  private _eventData: T | null = null;
  private _unsubscribeEventStore: (() => void) | null = null;

  private _subscribers = new Set<(data: T | null) => void>();

  constructor({ path, eventPrefix }: MergedStoreOptions) {
    this._globalStore = new FirebaseStore(createReference(...path));
    this._eventStore = new FirebaseStore(createReference(eventPrefix, ...path));
  }

  async get() {
    [this._eventData, this._globalData] = await Promise.all([
      this._eventStore.get(),
      this._globalStore.get(),
    ]);

    return this._getMergedData();
  }

  async update(partialData: PartialDeep<T>, global = false) {
    return global ? this._globalStore.update(partialData) : this._eventStore.update(partialData);
  }

  async set(data: T, global = false) {
    return global ? this._globalStore.set(data) : this._eventStore.set(data);
  }

  async transaction(callback: (data: T | null) => T | null, global = false) {
    return global
      ? this._globalStore.transaction(callback)
      : this._eventStore.transaction(callback);
  }

  subscribe(callback: (data: T | null) => void): () => void {
    this._subscribers.add(callback);

    let initialDataReceived = false;

    const subscribeToModel = (type: "global" | "event") => {
      const modelKey = type === "global" ? "_globalStore" : "_eventStore";
      const dataKey = type === "global" ? "_globalData" : "_eventData";

      this[modelKey].subscribe((data) => {
        this[dataKey] = data;

        if (!initialDataReceived) {
          initialDataReceived = true;
          return;
        }

        this._subscribers.forEach((callback) => callback(this._getMergedData()));
      });
    };

    subscribeToModel("global");
    subscribeToModel("event");

    return () => {
      this._subscribers.delete(callback);

      if (this._subscribers.size === 0) {
        this.disconnect();
      }
    };
  }

  disconnect() {
    this._subscribers.clear();
    this._unsubscribeGlobalStore?.();
    this._unsubscribeGlobalStore = null;
    this._unsubscribeEventStore?.();
    this._unsubscribeEventStore = null;
  }

  private _getMergedData() {
    const data = [this._eventData, this._globalData].filter((d) => d !== null);
    return data.length > 0 ? (merge(...data) as T) : null;
  }
}
