import { createTypedRouter } from "@smartphoneorchestra/router";

type _ = Record<string, never>;

export type Routes = {
  Welcome: _;
  Registration: _;
  PrivacyPolicy: _;
  Selfie: _;
  Onboarding: _;
  Interface: _;
};

const {
  Link,
  Redirect,
  Route: Screen,
  Router,
  useParams,
  useRoute,
  useRouter,
} = createTypedRouter<Routes>();

export { Link, Redirect, Router, Screen, useParams, useRoute, useRouter };
