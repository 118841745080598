import type { InterfaceData, UserData } from "@ancestors/models";
import type { CreateUserError } from "@ancestors/models";
import { createContext, useContext } from "react";
import invariant from "tiny-invariant";

export interface UserContextValue {
  user: UserData | null;
  currentInterface: InterfaceData | null;

  signIn: (username: string) => Promise<"username-not-found" | null>;
  signUp: (username: string) => Promise<CreateUserError | null>;
  signOut: () => void;
  updateUser: (partialUser: Partial<UserData>) => void;
  updateUserGroup: (partialUser: Partial<UserData> & { name: string }) => void;
  incrementUserGroupSelectedIndex: (name: string) => void;
}

export const userContext = createContext<UserContextValue | null>(null);

export function useUser() {
  const context = useContext(userContext);
  invariant(context, "`useUser` must be used within a `UserProvider`");
  return context;
}
