import { MergedStore } from "../lib/MergedStore";

const FIREBASE_KEY = "interfaces";

// TYPES
// -----

export interface InterfaceData {
  type: string;
  [key: string]: any;
}

export interface InterfacesByName {
  [name: string]: InterfaceData;
}

// INTERFACE
// ---------

interface InterfaceOptions {
  eventPrefix: string;
  name: string;
}

export class InterfaceStore extends MergedStore<InterfaceData> {
  constructor({ eventPrefix, name }: InterfaceOptions) {
    super({
      path: [FIREBASE_KEY, name],
      eventPrefix,
    });
  }
}

// ALL INTERFACES
// --------------

interface AllInterfacesOptions {
  eventPrefix: string;
}

export class AllInterfacesStore extends MergedStore<InterfacesByName> {
  constructor({ eventPrefix }: AllInterfacesOptions) {
    super({
      path: [FIREBASE_KEY],
      eventPrefix,
    });
  }
}
