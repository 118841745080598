import type { DatabaseReference } from "firebase/database";
import { update } from "firebase/database";
import { createReference } from "../lib/firebase";
import { FirebaseStore } from "../lib/FirebaseStore";
import type { DescendantsById } from "./DescendantStore";

const FIREBASE_KEY = "rounds";

// TYPES
// -----

/** A generation in the family tree. List of descendant ids */
export type RoundData = string[];

export type RoundsByIndex = RoundData[];

// ROUND
// -----

interface RoundOptions {
  eventPrefix: string;
  index: number;
}

export class RoundStore extends FirebaseStore<RoundData> {
  constructor({ eventPrefix, index }: RoundOptions) {
    super(createReference(eventPrefix, FIREBASE_KEY, String(index)));
  }
}

// ROUNDS
// ------

interface AllRoundsOptions {
  eventPrefix: string;
}

export class AllRoundsStore extends FirebaseStore<RoundsByIndex> {
  private eventStoreRef: DatabaseReference;

  constructor({ eventPrefix }: AllRoundsOptions) {
    super(createReference(eventPrefix, FIREBASE_KEY));
    this.eventStoreRef = createReference(eventPrefix);
  }

  // async createRound(round: RoundData) {
  //   // This creates a unique id for the round that can be sorted chronologically
  //   // https://firebase.google.com/docs/database/web/lists-of-data#append_to_a_list_of_data
  //   const ref = push(this._ref);
  //   await set(ref, round);
  // }

  async setRounds(rounds: RoundsByIndex, descendants: DescendantsById) {
    return await update(this.eventStoreRef, { rounds, descendants });
  }
}
