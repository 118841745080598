import debounce from "just-debounce";
import throttle from "just-throttle";
import { createReference } from "../lib/firebase";
import { FirebaseStore } from "../lib/FirebaseStore";

/** Key in firebase */
const FIREBASE_KEY = "presence";

/** The interval at which `lastSeen` when tracking presence  */
const PING_INTERVAL = 60 * 1000;

/** The interval at which subscriptions to presence are throttled */
const THROTTLE_INTERVAL = 10 * 1000;

/** The time after which a user is considered offline */
const OFFLINE_THRESHOLD = 2 * 60 * 1000; // 2 minutes

// TYPES
// -----

export interface PresenceData {
  [userId: string]: {
    /** The date the user was last seen */
    lastSeen: string;
  };
}

export interface GroupedPresenceData {
  online: { userId: string; lastSeen: Date }[];
  offline: { userId: string; lastSeen: Date }[];
}

// PRESENCE
// --------

interface PresenceStoreOptions {
  eventPrefix: string;
}

export class PresenceStore extends FirebaseStore<PresenceData> {
  constructor({ eventPrefix }: PresenceStoreOptions) {
    super(createReference(eventPrefix, FIREBASE_KEY));
  }

  /** Updates the lastSeen time of a user periodically in the database */
  trackPresence(userId: string) {
    const updateLastSeen = async () => {
      this.update({ [userId]: { lastSeen: new Date().toISOString() } });
    };

    const interval = setInterval(updateLastSeen, PING_INTERVAL);

    updateLastSeen();

    return () => {
      clearInterval(interval);
    };
  }

  /** Returns the presence of all users grouped by online/offline */
  async getPresence() {
    const data = (await this.get()) ?? {};
    const presence: GroupedPresenceData = {
      online: [],
      offline: [],
    };

    for (const userId in data) {
      const lastSeen = new Date(data[userId].lastSeen);

      if (lastSeen.getTime() > Date.now() - OFFLINE_THRESHOLD) {
        presence.online.push({
          userId,
          lastSeen: new Date(data[userId].lastSeen),
        });
      } else {
        presence.offline.push({
          userId,
          lastSeen: new Date(data[userId].lastSeen),
        });
      }
    }

    return presence;
  }

  subscribeToPresence(callback: (presence: GroupedPresenceData) => void) {
    let state: "listening" | "destroyed" = "listening";

    // Make sure we don't fire the callback too often
    const throttledCallback = throttle(async () => {
      if (state === "destroyed") return;
      callback(await this.getPresence());
    }, THROTTLE_INTERVAL);

    // Make sure we fire the callback at least every THROTTLE_INTERVAL
    const debouncedCallback = debounce(() => {
      throttledCallback();
    }, PING_INTERVAL);

    const unsubscribe = this.subscribe(() => {
      throttledCallback();
      debouncedCallback();
    });

    return () => {
      state = "destroyed";
      unsubscribe();
    };
  }
}
