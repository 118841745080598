import { useLocalStorage } from "@ancestors/components/useLocalStorage";
import type { UserData } from "@ancestors/models";
import { useCallback, useEffect, useMemo } from "react";
import invariant from "tiny-invariant";
import { useEventStore } from "../eventStore/eventStoreContext";
import type { UserContextValue } from "./userContext";
import { userContext } from "./userContext";
import { useStore } from "~/hooks/useStore";

interface UserProviderProps {
  children: React.ReactNode;
}

export function UserProvider({ children }: UserProviderProps) {
  const { eventStore } = useEventStore();

  // `username` is stored in local storage so the user can continue where they left off if they
  // refresh the page
  const [username, setUsername] = useLocalStorage<string | null>("ancestors:username", null);

  // When the username changes, the userStore is updated, this way we automatically subscribe to the
  // right user based on the localstorage username
  const userStore = useMemo(
    () => (username ? eventStore.user(username) : null),
    [eventStore, username],
  );

  // Track presence of the user
  useEffect(() => {
    if (username && userStore) {
      return eventStore.presence.trackPresence(username);
    }
  }, [eventStore.presence, userStore, username]);

  // Subscribe to store updates
  const interfaces = useStore(eventStore.allInterfaces);
  const user = useStore(userStore);

  // Get the current interface
  const currentInterface = useMemo(() => {
    if (!user?.currentInterface) return null;
    return interfaces?.[user.currentInterface] ?? null;
  }, [interfaces, user]);

  // Mutations
  const signIn = useCallback(
    async (username: string) => {
      const normalizedUsername = await eventStore.allUsers.usernameExists(username);

      if (!normalizedUsername) {
        return "username-not-found";
      }

      setUsername(normalizedUsername);

      return null;
    },
    [eventStore.allUsers, setUsername],
  );

  const signUp = useCallback(
    async (username: string) => {
      const error = await eventStore.allUsers.createUser(username);

      if (error) {
        return error;
      }

      setUsername(username);
      eventStore.userAgent.push(navigator.userAgent);

      return null;
    },
    [eventStore.allUsers, eventStore.userAgent, setUsername],
  );

  const signOut = useCallback(() => {
    setUsername(null);
  }, [setUsername]);

  const updateUser = useCallback(
    (partialUser: Partial<UserData>) => {
      invariant(userStore, "`userStore` should be defined");
      userStore.update(partialUser);
    },
    [userStore],
  );

  const updateUserGroup = useCallback(
    (partialUser: Partial<UserData> & { name: string }) => {
      eventStore.allUsers.updateUserGroup(partialUser);
    },
    [eventStore.allUsers],
  );

  const incrementUserGroupSelectedIndex = useCallback(
    (name: string) => {
      eventStore.allUsers.incrementUserGroupSelectedIndex(name);
    },
    [eventStore.allUsers],
  );

  const contextValue = useMemo<UserContextValue>(
    () => ({
      user,
      currentInterface,

      signIn,
      signUp,
      signOut,
      updateUser,
      updateUserGroup,
      incrementUserGroupSelectedIndex,
    }),
    [
      currentInterface,
      incrementUserGroupSelectedIndex,
      signIn,
      signOut,
      signUp,
      updateUser,
      updateUserGroup,
      user,
    ],
  );

  return <userContext.Provider value={contextValue}>{children}</userContext.Provider>;
}
