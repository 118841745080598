/// <reference path="../../../node_modules/vite/types/importMeta.d.ts" />

import { set } from "firebase/database";
import { createReference } from "./lib/firebase";
import { DashboardGenerationState, DashboardStore } from "./models/DashboardStore";
import { AllDescendantsStore, Descendant } from "./models/DescendantStore";
import { AllEventsStore } from "./models/EventStore";
import { AllInterfacesStore, InterfaceStore } from "./models/InterfaceStore";
import { PresenceStore } from "./models/PresenceStore";
import { AllRoundsStore, RoundStore } from "./models/RoundStore";
import { SequenceSettingsStore } from "./models/SequenceSettings";
import { SequenceStore } from "./models/SequenceStore";
import { UserAgentStore } from "./models/UserAgentStore";
import { AllUsersStore, UserStore } from "./models/UserStore";

const FOUR_HOURS = 4 * 60 * 60 * 1000;
const IS_DEVELOPMENT = (import.meta.env?.MODE || process.env.NODE_ENV) === "development";

// GET EVENTS
// ----------

interface GetEventsOptions {
  date?: Date;
}

export function getEvents({ date = new Date() }: GetEventsOptions = {}) {
  return new AllEventsStore({ date: getEventDateString(date) });
}

// GET EVENT DATA
// -------------

interface GetEventDataOptions {
  eventId?: string | null;
  date?: Date;
  mode?: "development" | "production";
}

export function getEventStore({
  eventId,
  date = new Date(),
  mode = IS_DEVELOPMENT ? "development" : "production",
}: GetEventDataOptions = {}) {
  const eventPrefix = [
    // Prefix the key with the event date, so each event has its own data
    getEventDateString(date),

    // Optionally add a event id to the prefix
    // This allows for multiple events to be run at the same time
    eventId,

    // Separate dev and prod data
    mode === "development" ? "dev" : null,
  ]
    .filter(Boolean)
    .join("-");

  return {
    eventKey: eventPrefix,

    // Dashboard
    dashboard: new DashboardStore({ eventPrefix }),

    // Descendants
    descendant: (id: string) => new Descendant({ eventPrefix, id }),
    allDescendants: new AllDescendantsStore({ eventPrefix }),

    // Interfaces
    interface: (name: string) => new InterfaceStore({ eventPrefix, name }),
    allInterfaces: new AllInterfacesStore({ eventPrefix }),

    // Presence
    presence: new PresenceStore({ eventPrefix }),

    // Rounds
    round: (index: number) => new RoundStore({ eventPrefix, index }),
    allRounds: new AllRoundsStore({ eventPrefix }),

    // Sequence
    sequence: new SequenceStore({ eventPrefix }),
    sequenceSettings: new SequenceSettingsStore({ eventPrefix }),

    // Users
    user: (name: string) => new UserStore({ eventPrefix, name }),
    allUsers: new AllUsersStore({ eventPrefix }),

    // Useragent
    userAgent: new UserAgentStore({ eventPrefix }),

    resetDatabase: async () => {
      await set(createReference(eventPrefix), {
        dashboard: {
          started: false,
          round2State: DashboardGenerationState.initial,
          round3State: DashboardGenerationState.initial,
          round4State: DashboardGenerationState.initial,
          round5State: DashboardGenerationState.initial,
          round6State: DashboardGenerationState.initial,
        },
      });
    },
  };
}

// TYPES
// -----

export type EventStore = ReturnType<typeof getEventStore>;
export type { Store } from "./lib/Store";
export { DashboardGenerationState } from "./models/DashboardStore";
export type { DashboardData } from "./models/DashboardStore";
export type { DescendantData, DescendantsById } from "./models/DescendantStore";
export type { EventData } from "./models/EventStore";
export type { InterfaceData, InterfacesByName } from "./models/InterfaceStore";
export type { GroupedPresenceData, PresenceData } from "./models/PresenceStore";
export type { RoundData, RoundsByIndex } from "./models/RoundStore";
export type { UserData, UsersByName, CreateUserError } from "./models/UserStore";

// UTILS
// -----

function getEventDateString(date: Date) {
  // Date starts at 4am instead of 12am just be safe to not break events that go past midnight
  return new Date(date.getTime() - FOUR_HOURS).toISOString().split("T")[0];
}
