import type { FirebaseOptions } from "firebase/app";
import { initializeApp } from "firebase/app";
import { getDatabase, ref } from "firebase/database";

// These options can be found in the Firebase console under the project settings
const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyA8OgoofOrxvpKzl5-BpEgvOu_iFEUv2aU",
  databaseURL: "https://ancestors-839a1-default-rtdb.europe-west1.firebasedatabase.app",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

// eslint-disable-next-line filenames-simple/named-export
export function createReference(...keys: string[]) {
  return ref(database, keys.join("/"));
}
