export function GradientSvgPath({
  path: Path,
}: {
  path: React.FC<React.SVGProps<SVGPathElement>>;
}) {
  return (
    <>
      <defs>
        <mask id="letters" x="0" y="0" width="574" height="92">
          <rect x="0" y="0" width="100%" height="100%" fill="black" />
          <Path fill="white" />
        </mask>

        <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="gradient">
          <animate attributeName="y1" values="0%;100%;0%" dur="5s" repeatCount="indefinite" />
          <stop offset="0%" stopColor="#FF8080">
            <animate
              attributeName="stop-color"
              values="#FF8080;#FFDF80; #152323;#FF8080"
              dur="20s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="100%" stopColor="#FFDF80">
            <animate
              attributeName="stop-color"
              values="#152323;#FF8080;#FFDF80; #152323"
              dur="20s"
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>
      ;
      <rect width="100%" height="100%" fill="url(#gradient)" mask="url(#letters)" />
    </>
  );
}
