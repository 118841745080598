import { Button } from "@ancestors/components/Button";
import { Container } from "@ancestors/components/Container";
import { LogoAncestors } from "@ancestors/components/LogoAncestors";
import { LogoSPO } from "@ancestors/components/LogoSPO";
import { useUser } from "~/providers/user/userContext";
import { Link, useRouter } from "~/router";

export function WelcomeScreen() {
  const router = useRouter();
  const { user, signOut } = useUser();

  const handleJoinAsExistingUser = () => {
    switch (true) {
      case !user?.selfieUrl:
        return router.navigate("Selfie");
      case !user?.currentInterface:
        return router.navigate("Onboarding");
      default:
        return router.navigate("Interface");
    }
  };

  const handleJoinAsNewUser = () => {
    signOut();
    setTimeout(() => router.navigate("Registration"));
  };

  return (
    <Container>
      <div className="grid h-full gap-4">
        <div className="flex flex-col items-center justify-center gap-2">
          <LogoSPO className="w-[60%] max-w-xs" />
          <p className="text-lg">PRESENTS</p>
        </div>

        <div className="flex flex-col items-center">
          <LogoAncestors className="max-w-md" variant="gradient" />
        </div>

        <div className="flex flex-wrap items-center justify-center">
          <div className="flex flex-wrap items-center justify-center gap-4">
            {user ? (
              <>
                <Button onClick={handleJoinAsExistingUser}>I am {user.name}</Button>
                <Button variant="secondary" onClick={handleJoinAsNewUser}>
                  I am not {user.name}
                </Button>
              </>
            ) : (
              <Button asChild>
                <Link to="Registration">Join</Link>
              </Button>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}
