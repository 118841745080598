import type { Store } from "@ancestors/models";
import { useState, useEffect } from "react";

export function useStore<T extends Record<string, any>>(store: Store<T> | null) {
  const [data, setData] = useState<T | null>(null);

  useEffect(() => {
    if (store) {
      return store.subscribe((data) => {
        setData(data);
      });
    } else {
      // eslint-disable-next-line @eslint-react/hooks-extra/no-direct-set-state-in-use-effect
      setData(null);
    }
  }, [store]);

  return data;
}
