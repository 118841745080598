import invariant from "tiny-invariant";
import { createReference } from "../lib/firebase";
import { FirebaseStore } from "../lib/FirebaseStore";

const FIREBASE_KEY = "dashboard";

export enum DashboardGenerationState {
  "initial",
  "processing",
  "done",
}

export interface DashboardData {
  command: string | null;
  currentInterface: string | null;
  started: boolean;
  round2State: DashboardGenerationState;
  round3State: DashboardGenerationState;
  round4State: DashboardGenerationState;
  round5State: DashboardGenerationState;
  round6State: DashboardGenerationState;
  round7State: DashboardGenerationState /* Extra images for an animation */;
}

interface DashboardOptions {
  eventPrefix: string;
}

export class DashboardStore extends FirebaseStore<DashboardData> {
  constructor({ eventPrefix }: DashboardOptions) {
    super(createReference(eventPrefix, FIREBASE_KEY));
  }

  // TODO, sorry getting a bit annoyed with typescript; can we create a method that can update the round states
  // in 1 single method based on a given round parameter that feels more dynamic than a switch?
  async updateRoundState(round: number, state: DashboardGenerationState) {
    return this.transaction((dashboard) => {
      invariant(dashboard, "`dashboard` should be defined");
      switch (round) {
        case 2:
          dashboard.round2State = state;
          break;

        case 3:
          dashboard.round3State = state;
          break;

        case 4:
          dashboard.round4State = state;
          break;

        case 5:
          dashboard.round5State = state;
          break;

        case 6:
          dashboard.round6State = state;
          break;

        case 7:
          dashboard.round7State = state;
          break;
      }

      return dashboard;
    });
  }
}
