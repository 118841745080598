import { useLocalStorage } from "@ancestors/components/useLocalStorage";
import { getEventStore } from "@ancestors/models";
import { useMemo } from "react";
import type { EventStoreContextValue } from "./eventStoreContext";
import { eventStoreContext } from "./eventStoreContext";

interface GlobalContextProviderProps {
  children: React.ReactNode;
}

export function EventStoreProvider({ children }: GlobalContextProviderProps) {
  // `eventId` is stored in local storage so the user can continue where they left off if they
  // refresh the page
  const [eventId, setEventId] = useLocalStorage<string | null>("ancestors:eventId", null);

  // When the eventId changes, the eventStore is updated
  const eventStore = useMemo(() => getEventStore({ eventId }), [eventId]);

  console.log("eventKey", eventStore.eventKey);

  const contextValue = useMemo<EventStoreContextValue>(
    () => ({ eventStore, setEventId }),
    [eventStore, setEventId],
  );

  return <eventStoreContext.Provider value={contextValue}>{children}</eventStoreContext.Provider>;
}
